import Pui9HttpRequests from 'pui9-requests';

export async function getUserVisits(body) {
	const controller = `/visitvisitor/list`;
	let data = [];
	await Pui9HttpRequests.postRequest(
		controller,
		body,
		response => {
			if (response && response.data) {
				data = response.data.data;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		},
		{
			workingRole: 'ADMIN'
		}
	);

	return data;
}

export async function exportVisitsVisitors(body) {
	const controller = `/visitvisitor/export`;
	let data = [];
	await Pui9HttpRequests.postRequest(
		controller,
		body,
		response => {
			if (response && response.data) {
				data = response;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		}
	
	);

	return data;
}

<template>
	<div v-if="visits.length" class="products-catalog">
		<UserVisitExport :columnsToExport="columnsToExport" />
		<div class="products-catalog__container" style="margin-top: 2rem">
			<table style="border-collapse: collapse; border-spacing: 0; width: 100%">
				<tr>
					<th class="products-catalog__header-text" style="cursor: pointer">{{ $t('visits.visiteventname') }}</th>
					<th class="products-catalog__header-text" style="cursor: pointer">{{ $t('visits.visitexhiiname') }}</th>
					<th class="products-catalog__header-text" style="cursor: pointer">{{ $t('visits.visitcontactexhii') }}</th>
					<th class="products-catalog__header-text" style="cursor: pointer">{{ $t('visits.visitpabilion') }}</th>
					<th class="products-catalog__header-text" style="cursor: pointer">{{ $t('visits.visitstand') }}</th>
					<th class="products-catalog__header-text" style="cursor: pointer">{{ $t('visits.visitinidate') }}</th>
					<th class="products-catalog__header-text" style="cursor: pointer">{{ $t('visits.visitenddate') }}</th>
					<th class="products-catalog__header-text" style="cursor: pointer">{{ $t('visits.visitresumeemail') }}</th>
					<th class="products-catalog__header-text" style="cursor: pointer">{{ $t('visits.visitinfoemail') }}</th>
				</tr>

				<tr v-for="item in visits" :key="item.vsitid">
					<td class="products-catalog__body-row-sector">{{ item.eventname }}</td>
					<td class="products-catalog__body-row-sector">{{ item.exhiname }}</td>
					<td class="products-catalog__body-row-sector">{{ item.exhicontactperson }}</td>
					<td class="products-catalog__body-row-sector">{{ item.exhipavilion }}</td>
					<td class="products-catalog__body-row-sector">{{ item.exhistand }}</td>
					<td class="products-catalog__body-row-sector">{{ item.vsitdateini }}</td>
					<td class="products-catalog__body-row-sector">{{ item.vsitdateend }}</td>
					<td v-if="item.vsitsendinfoemail == 1" class="products-catalog__body-row-sector">{{ $t('visits.visityes') }}</td>
					<td v-else class="products-catalog__body-row-sector">{{ $t('visits.visitno') }}</td>

					<td v-if="item.vsitreceiveinfoemail == 1" class="products-catalog__body-row-sector">{{ $t('visits.visityes') }}</td>
					<td v-else class="products-catalog__body-row-sector">{{ $t('visits.visitno') }}</td>
				</tr>
			</table>
		</div>
	</div>
	<VisitEmpty v-else all />
</template>

<script>
import VisitEmpty from '@/components/visits/VisitEmpty.vue';
import UserVisitExport from '@/components/visits/UserVisitExport.vue';

export default {
	name: 'UserVisitTabsAll',
	props: {
		visits: [],
		user: {}
	},
	data() {
		return {
			showProgress: false,
			columnsToExport: [
				{
					order: 0,
					title: this.$t('visits.visiteventname'),
					name: 'eventname',
					dateformat: 'dd/MM/yyyy HH:mm'
				},
				{
					order: 1,
					title: this.$t('visits.visitexhiiname'),
					name: 'exhiname',
					dateformat: 'dd/MM/yyyy HH:mm'
				},
				{
					order: 2,
					title: this.$t('visits.visitcontactexhii'),
					name: 'exhicontactperson',
					dateformat: 'dd/MM/yyyy HH:mm'
				},
				{
					order: 3,
					title: this.$t('visits.visitpabilion'),
					name: 'exhipavilion',
					dateformat: 'dd/MM/yyyy HH:mm'
				},
				{
					order: 4,
					title: this.$t('visits.visitstand'),
					name: 'exhistand',
					dateformat: 'dd/MM/yyyy HH:mm'
				},
				{
					order: 5,
					title: this.$t('visits.visitinidate'),
					name: 'vsitdateini',
					dateformat: 'dd/MM/yyyy HH:mm'
				},
				{
					order: 6,
					title: this.$t('visits.visitenddate'),
					name: 'vsitdateend',
					dateformat: 'dd/MM/yyyy HH:mm'
				},
				{
					order: 7,
					title: this.$t('visits.visitresumeemail'),
					name: 'vsitsendinfoemail',
					dateformat: 'dd/MM/yyyy HH:mm'
				},
				{
					order: 8,
					title: this.$t('visits.visitinfoemail'),
					name: 'vsitreceiveinfoemail',
					dateformat: 'dd/MM/yyyy HH:mm'
				}
			]
		};
	},
	components: { VisitEmpty, UserVisitExport },
	mounted() {},
	created() {
		this.subscribeToEvents();
	},
	methods: {
		subscribeToEvents() {
			this.$puiEvents.$on('searching-true', () => {
				this.showProgress = true;
			});
			this.$puiEvents.$on('searching-false', () => {
				this.showProgress = false;
			});
		}
	},
	computed: {
		primaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	}
};
</script>


<style lang="scss" scoped>
.appointments_container {
	padding-left: 24px;
	display: flex;
}

.appointments_container_alert {
	display: flex;
	flex-direction: column;

	&-top {
		display: flex;
		align-items: center;
	}

	&-bell {
		width: 16.67px;
		height: 20px;
	}
	&-hour {
		padding-left: 4.67px;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		line-height: 15px;

		&-span {
			color: rgb(160, 159, 159);
			font-weight: normal;
		}
	}
}

.appointments-container-observations-main-chat-left {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	margin-left: 20px;

	&-top {
		display: flex;
		align-items: center;
	}

	&-icon {
		width: 16.67px;
		height: 20px;
	}
	&-text {
		padding-left: 4.67px;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		line-height: 15px;

		&-span {
			color: rgb(160, 159, 159);
			font-weight: normal;
		}
	}
}

.appointments-container-observations-main-chat-rigth {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	margin-right: 20px;
	align-items: flex-end;

	&-top {
		display: flex;
		align-items: center;
	}

	&-icon {
		width: 16.67px;
		height: 20px;
	}
	&-text {
		padding-right: 4.67px;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		line-height: 15px;

		&-span {
			color: rgb(160, 159, 159);
			font-weight: normal;
		}
	}
}
.appointments_container-subtitle {
	&-left {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 15px;
		/* or 125% */

		/* Neutral / Extra-dark grey */

		color: #293133;
		padding-left: 6px;
		margin-left: 25px;
		margin-top: 5px;
		border-left: 2px solid #d4af37;
	}

	&-right {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 15px;
		/* or 125% */
		text-align: right;
		/* Neutral / Extra-dark grey */

		color: #293133;
		padding-right: 6px;
		margin-right: 25px;
		margin-top: 5px;
		margin-bottom: 20px;
		border-right: 2px solid #d4af37;
	}
}
.appointments_container-buttons {
	padding-left: 30px;
	&-right {
		&-accept {
			color: #fff;
			padding: 10.5px 19px !important;
			margin: 5px !important;
			border-radius: 8px !important;
		}
		&-deny {
			color: #575e60;
			border: 1px solid #d2d7d9 !important;
			padding: 10.5px 19px !important;

			margin: 5px !important;
			border-radius: 8px !important;
		}
	}
}

.appointments-container-observations {
	font-family: Cabin;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;

	/* Neutral / Extra-dark grey */

	color: #293133;
	padding-left: 24px;
	padding-top: 20px;
	padding-bottom: 15px;
}
.appointments-container-observations-main {
	background: #ffffff;
	/* Neutral / Medium-light grey */
	width: 650px;
	border: 1px solid #e3e7e8;
	box-sizing: border-box;
	border-radius: 8px;
	margin-left: 24px;
	margin-bottom: 24px;
}
table {
	border-collapse: collapse;
}

tr {
	border-top: 1px solid #e3e7e8;
	cursor: pointer;
}

td {
	height: 5.625rem;
}
.status {
	&-accepted {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		/* identical to box height, or 150% */

		/* UI / Green */

		color: #24a54e;
		background: rgba(36, 165, 78, 0.2);
		border-radius: 6px;
		padding: 5px 8px;
	}
	&-denied {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		/* identical to box height, or 150% */

		/* UI / Red */

		color: #e8311a;
		background: rgba(232, 49, 26, 0.2);
		border-radius: 6px;
		padding: 5px 8px;
	}

	&-progress {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		/* identical to box height, or 150% */

		/* UI / Red */

		color: white;
		background: #d4af37;
		border-radius: 6px;
		padding: 5px 8px;
		width: auto;
	}
}

.loading-circle {
	display: flex;
	justify-content: center;
	text-align: center;
}

.fas.fa-chevron-up,
.fas.fa-chevron-down {
	margin-left: 1rem;
}

.products-catalog {
	background-color: #f3f5f5;
	padding-left: 3%;
	padding-right: 3%;
	padding-top: 40px;
	padding-bottom: 40px;
}

.products-catalog__container {
	background-color: #fff;
	border: 1px solid #e3e7e8;
	border-radius: 8px;
	overflow-x: auto;
}

.products-catalog__header {
	&-text {
		padding: 1.375rem 1.3rem;
		text-align: start;
	}
}

.products-catalog__body-row-image {
	padding: 0 1.25rem;
	text-align: center;

	&--size {
		margin: 0 auto;
		max-width: 7.125rem;
		height: 3.875rem;
		object-fit: cover;
	}
}

.products-catalog__body-row-name {
	&-NEW {
		color: #fff;
		background-color: #e36f05;
		border-radius: 6px;
		padding: 0.125rem 0.375rem;
		font-weight: 500;
		font-size: 0.75rem;
	}

	&-text {
		font-family: Cabin;
		font-weight: bold;
		font-size: 1rem;
		color: #293133;
	}
}

.products-catalog__body-row-sector {
	align-items: center;
	padding: 1.375rem 1.3rem;

	&-text {
		font-size: 1rem;
		color: #575e60;
		width: 15.5rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.products-catalog__body-row-status {
	align-items: center;

	&-text {
		font-size: 50rem;
		color: #575e60;
		width: 50rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.products-catalog__body-row-exhibitor {
	font-size: 1rem;
	color: #575e60;
	padding-right: 30px;
}

.products-catalog__body-row-social {
	&-item {
		padding: 0 0.75rem;
	}
}
.products-catalog__body-row-notext {
	display: flex;
	justify-content: center;
	padding: 8px 10px;
	border: 1px solid #ddd;
	font-size: 14px !important;
	color: #464f63;
	font-weight: 400;
	background-color: #f9f9f9;
}
.products-catalog__body-row-notext:hover {
	background-color: var(--main-10) !important;
}
.products-catalog__body-row-container-titol {
	display: flex;
	justify-content: center;
	font-size: 20px !important;
	color: black;
	font-weight: bold;
	text-align: center;
	margin-bottom: 0.5rem;
}

.products-catalog__body-row-container-text {
	display: flex;
	justify-content: center;
	font-size: 14px !important;
	color: #464f63;
	font-weight: 400;
	text-align: center;
}
.products-catalog__pagination {
	display: flex;
	justify-content: center;
	align-items: baseline;
	padding-top: 1rem;

	&-info {
		margin-right: 0.5rem;
	}

	&-button {
		padding: 0.5rem 0.75rem;
		margin: 0.125rem;
		border-radius: 6px;
		background-color: #f5f7f7;
	}

	&-select {
		width: 4rem;
		margin: 0 0.25rem;
	}
}
</style>

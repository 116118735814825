<template>
	<div class="interest-empty">
		<h2 class="interest-empty-title">{{ title }}</h2>
		<span class="interest-empty-subtitle"> {{ subtitle }} </span>
		<v-btn v-if="all" :to="{ name: 'Exhibitors' }" class="interest-empty-button" color="#2498FF" depressed> {{ button }}</v-btn>
	</div>
</template>

<script>
export default {
	name: 'InterestEmpty',

	props: {
		all: Boolean,
		accepted: Boolean,
		progress: Boolean,
		rejected: Boolean
	},

	data() {
		return {
			title: null,
			subtitle: null,
			button: null
		};
	},

	mounted() {
		this.getInfo();
	},

	methods: {
		getInfo: function () {
			if (this.all) {
				this.title = this.$t('visits.empty.titleAll');
				this.subtitle = this.$t('visits.empty.subTitleAll');
				this.button = this.$t('visits.empty.buttonExhibitors');
			}
		}
	},

	watch: {
		'$store.state.activeLanguage': 'getInfo'
	}
};
</script>


<style lang="scss" scoped>
.interest-empty {
	height: 80vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&-title {
		font-family: Cabin;
		font-weight: bold;
		font-size: 26px;
		color: #293133;
	}

	&-subtitle {
		font-size: 20px;
		color: #575e60;
	}

	&-button {
		margin-top: 1.5rem;
		color: #fff;
		border-radius: 8px !important;
	}
}
</style>

<template>
	<div class="user-visit-export">
		<v-menu offset-y rounded="lg">
			<template v-slot:activator="{ on, attrs }">
				<button class="user-visit-export__button" v-bind="attrs" v-on="on" style="border: 1px solid #d2d7d9">
					<i class="user-visit-export__button-icon fas fa-file-export"></i>
				</button>
			</template>
			<v-list>
				<a
					v-for="(option, i) in options"
					:key="i"
					class="user-visit-export__item-link"
					target="_blank"
					href=""
					@click.prevent="downloadDocument(option.exportType)"
				>
					<v-list-item class="user-visit-export__item">
						<v-list-item-title class="user-visit-export__item-container">
							<i :class="'user-visit-export__item-icon ' + option.icon"></i>
							<span class="user-visit-export__item-title">{{ option.name }}</span>
						</v-list-item-title>
					</v-list-item>
				</a>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
import { exportVisitsVisitors } from '@/api/visits.js';
export default {
	name: 'UserVisitExport',

	props: {
		columnsToExport: [Array, Object]
	},
	data() {
		return {
			options: [
				// { name: 'PDF', icon: 'fas fa-file-pdf', exportType: 'pdf' },
				{ name: 'CSV', icon: 'fas fa-file-csv', exportType: 'csv' }
				// { name: 'Excel', icon: 'fas fa-file-excel', exportType: 'excel' }
			]
		};
	},

	components: {},

	methods: {
		downloadDocument: async function (exportType) {
			console.log(this.columnsToExport);
			const body = {
				model: 'visitvisitor',
				exportTitle: 'Visitas',
				exportType: exportType,
				exportColumns: this.columnsToExport,
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							data: this.$store.state.eventInfo.evenid,
							field: 'exhieventid',
							op: 'eq'
						},
						{
							data: JSON.parse(localStorage.getItem('pui9_session')).email, //CORREO DEL USUARIO
							field: 'useremail',
							op: 'eq'
						}
					]
				},
				order: [
					{
						column: 'vsitid',
						direction: 'desc'
					}
				],
				queryText: '',
				queryFields: [],
				queryFlexible: false,
				queryLang: 'es'
			};
			const data = await exportVisitsVisitors(body);
			console.log(data);
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(new window.Blob([data.data]));
			link.download = this.getFilenameFromHeaders(data.headers);
			link.click();
		},
		getFilenameFromHeaders: (headers) => {
			const disposition = headers['content-disposition'];
			if (disposition && disposition.indexOf('attachment') !== -1) {
				var filenameRegex = /filename=((['"]).*?\2|[^;\n]*)/;
				var matches = filenameRegex.exec(disposition);
				if (matches != null && matches[1]) {
					return matches[1].replace(/['"]/g, '');
				}
			}
			return null;
		}
	}
};
</script>


<style lang="scss" scoped>
.user-visit-export {
	&__button {
		border-radius: 8px;
		padding: 1.25rem 0.75rem;
		max-height: 2.5rem;
		display: flex;
		align-items: center;
		margin: 0 0.25rem;

		&-icon {
			font-size: 1.5rem;
		}
	}

	&__item {
		cursor: pointer;
		padding-right: 4rem;

		&:hover {
			background-color: #f3f5f5;
		}

		&-container {
			display: flex;
			align-items: center;
		}

		&-icon {
			font-size: 1.5rem;
			margin-right: 1rem;
		}

		&-link {
			text-decoration: none;
			color: #575e60;
		}
	}
}
</style>

<template>
	<div>
		<v-toolbar class="interests-tabs" :style="{ 'background-color': primaryColor }">
			<v-tabs v-model="tabs" dark>
				<v-tab>
					<v-badge color="white" :content="visits.length ? visits.length : '0'" inline>
						{{ $t('appointments.appointmentTabAll') }}
					</v-badge>
				</v-tab>

				<!--<v-tab><v-badge color="white" content="5" inline>Actividades</v-badge></v-tab>
				<v-tab><v-badge color="white" content="2" inline>Contactos</v-badge></v-tab>-->
			</v-tabs>
		</v-toolbar>
		<v-tabs-items v-model="tabs">
			<v-tab-item> <UserVisitTabsAll :visits="visits" :user="user" /> </v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import { getDocument } from '@/api/common.js';
import { getUserVisits } from '@/api/visits.js';
import UserVisitTabsAll from '@/components/visits/UserVisitTabsAll.vue';

//import ActivitiesInterests from '@/components/interests/ActivitiesInterests.vue';

export default {
	name: 'UserVisitTabs',
	props: {
		primaryColor: String,
		value: {
			type: [Object, Array]
		}
	},

	components: { UserVisitTabsAll },

	data() {
		return {
			tabs: null,
			defaultPhoto: null,
			visits: {},

			user: {}
		};
	},
	mounted() {
		this.checkLoginAndGetVisits();
		this.getDefaultPhoto();
	},
	created() {
		this.$puiEvents.$on('reloadAppointment', () => {
			this.checkLoginAndGetVisits();
		});
	},
	destroyed() {
		this.$puiEvents.$off('reloadAppointment');
	},
	methods: {
		checkLoginAndGetVisits() {
			this.checkLoginData();
			if (this.isLogged) {
				// lo ponemos a false para que los cards no existan
				this.user = JSON.parse(localStorage.getItem('pui9_session'));
				console.log(this.user);
				this.getUserVisits();
			}
		},

		getDefaultPhoto: async function () {
			const data = await getDocument('event', this.$store.state.eventInfo.evenid, 'event_app_primary');
			this.defaultPhoto = data[0].url;
		},

		checkLoginData() {
			if (localStorage.getItem('pui9_session') !== null) {
				this.isLogged = true;
			} else {
				this.isLogged = false;
			}
		},
		getUserVisits: async function () {
			if (this.isLogged) {
				var params = {
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								data: this.$store.state.eventInfo.evenid,
								field: 'exhieventid',
								op: 'eq'
							},
							{
								data: this.user.email, //CORREO DEL USUARIO
								field: 'vsitvisitorid',
								op: 'eq'
							}
						]
					},
					model: 'visitvisitor',

					order: [
						{
							column: 'vsitid',
							direction: 'desc'
						}
					],
					page: 1,
					queryFields: [],
					queryFlexible: false,
					queryText: null,
					rows: 999999999
				};
				let data = await getUserVisits(params);
				this.visits = data;

				console.log(this.visits);
			}
		}
	}
};
</script>


<style lang="scss">
.interests-tabs {
	box-shadow: none !important;
	padding-left: 10%;

	& > .v-toolbar__content > .v-tabs > .v-item-group {
		& > .v-slide-group__prev {
			display: none !important;
		}

		& > .v-slide-group__wrapper > .v-slide-group__content {
			& > .v-tabs-slider-wrapper > .v-tabs-slider {
				border-radius: 8px;
				height: 0.5rem;
			}

			& > .v-tab {
				padding: 0 !important;
				margin-right: 1.5rem !important;
				text-transform: none !important;

				& > .v-badge {
					// font-family: Cabin;

					& > .v-badge__wrapper > .v-badge__badge {
						color: #575e60;
					}
				}
			}
		}
	}
}
</style>

<template>
	<div class="home">
		<div class="home__navbar">
			<Navbar />
		</div>
		<div class="home__header" :style="{ 'background-color': primaryColor }">
			<h1 class="home__header-title">{{ $t('visits.visit') }}</h1>
		</div>
		<div class="home__body">
			<UserVisitTabs :primaryColor="primaryColor" />
		</div>
		<footer class="home__footer">
			<Footer />
		</footer>
	</div>
</template>

<script>
import Navbar from '@/components/navbar/Navbar.vue';
import Footer from '@/components/footer/Footer.vue';
import UserVisitTabs from '@/components/visits/UserVisitTabs.vue';
import { saveLogsAccess, receiveDateNow } from '@/api/common.js';

export default {
	name: 'UserVisits',
	title() {
		return `${this.$t('visits.visit')} | ${this.$store.state.eventInfo.evtrname}`;
	},
	components: {
		Navbar,
		UserVisitTabs,
		Footer
	},
	data() {
		return {
			image: '',
			colorStyle: {
				'--primaryColor': this.primaryColor
			}
		};
	},
	mounted() {
		const user = JSON.parse(localStorage.getItem('pui9_session'));

		saveLogsAccess({
			loacfecha: receiveDateNow(),
			loacusr: user ? user.usr : 'anonymous',
			loacfuncionalidad: "Listado de visitas",
			loaceventgroupid: this.$store.state.eventInfo.eveneventgroupid,
			loacurl: this.$route.path,
			loacip: user ? user.lastLoginIp : '0.0.0.0',
			loacenvironment: "CATÁLOGO",
			loacproductserviceid: 0,
			loacexhibitorid: 0
		});
	},
	computed: {
		primaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	},
	methods: {}
};
</script>

<style lang="scss" scoped>
.home {
	display: flex;
	flex-direction: column;
	width: 100%;

	&__navbar,
	&__header,
	&__footer {
		flex: none;
	}
	&__body {
		overflow-y: hidden;
		flex: auto;
	}
}

.home__header {
	height: 94px;
	display: flex;
	flex-direction: column;
	justify-content: end;
}

.home__header-title {
	font-family: Cabin;
	font-weight: bold;
	font-size: 2rem;
	letter-spacing: 0.0625rem;
	color: #fff;
	padding-left: 10%;
}

.home__body {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-color: #f3f5f5;
}

.home__body-sections {
	height: 100%;
	display: flex;
	align-items: center;
	padding-left: 10%;
	padding-top: 2rem;
	padding-bottom: 2rem;
}
</style>
